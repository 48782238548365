.enhanced_delivery_page {
  .page-wrapper {
    .pg_wrapper {
      .checkout_container {
        .checkout__content {
          #address-section-container {
            .shipping-panel__title {
              margin-top: 40px;
            }
          }
        }
      }
      .payment-panel {
        .transaction-details {
          .transaction-item {
            .transaction-content {
              margin-#{$ldirection}: 0;
            }
          }
        }
        #payment-type-cc {
          p.payment-select {
            display: none;
            & + span {
              .payment-type {
                border-top: 0;
              }
            }
          }
        }
        .cc-header {
          display: none;
        }
      }
    }
  }
}
