//
// scss/components/header/_page_navigation.scss
//
.page-navigation {
  .menu-reference {
    &.menu-reference--loyalty,
    &.menu-reference--signin,
    &.menu-reference--account {
      @media #{$medium-up} {
        display: none;
      }
    }
  }
}

.menu-reference {
  .page-navigation & {
    &:last-child {
      margin-bottom: 20px; //clear sticky footer
      @media #{$medium-up} {
        margin-bottom: 0;
      }
    }
  }
}

.elc-user-state-anonymous .page-navigation .menu-reference--loyalty.js-active .level-1 {
  background-image: $sprites-sprites;
  background-size: auto auto;
  background-position: 100% -6835px;
}

.level-2 {
  .menu-reference--loyalty & {
    padding: 15px 25px;
  }
  .elc-user-state-anonymous .menu-reference--loyalty & {
    display: none;
  }
}

// menu states
.elc-user-state-anonymous .page-navigation {
  .menu-reference--account {
    display: none;
  }
}

.elc-user-state-logged-in .page-navigation {
  .menu-reference--signin {
    display: none;
  }
}

//
// scss/components/header/_page_utilities.scss
//

.page-utilities-loyalty-elc-nodeblock {
  float: left;
}

.page-utilities__account-button {
  cursor: pointer;
  padding: 0 20px 0 10px;
  @media #{$small-only} {
    padding: 0;
  }
}

.page-utilities__account-text {
  .elc-user-state-logged-in & {
    font-size: 12px;
    font-weight: normal;
    text-transform: none;
    @media #{$small-only} {
      letter-spacing: 0;
      font-size: 11px;
      padding-top: 2px;
    }
  }
}

.user-login-state {
  @media #{$medium-up} {
    .page-utilities__loyalty & {
      position: static;
      min-width: 0;
    }
  }
}

// loyalty
.user-loyalty-state {
  text-transform: none;
  @media #{$medium-up} {
    display: block;
    position: absolute;
    font-size: 12px;
    font-weight: normal;
    text-align: right;
    right: 20px;
    min-width: 600px;
    @media #{$large-down} {
      @media #{$portrait} {
        top: 38px;
      }
    }
    #{$alt} & {
      color: $color-white;
    }
    .user-logged-out,
    .user-logged-in {
      display: none;
      &__link {
        margin-left: 9px;
        color: $color-navy;
        font-weight: normal;
        text-decoration: none;
        .js-loyalty-points-value {
          font-weight: bold;
        }
        #{$alt} & {
          color: $color-white;
        }
      }
    }
  }
  @media #{$large-up} {
    bottom: 0;
  }
  @media #{$medium-only} {
    right: 100px;
  }
  @media #{$large-only} {
    right: 10px;
  }
}

.page-utilities__loyalty {
  .user-greeting {
    margin-left: 9px;
    color: $color-navy;
    font-weight: normal;
    text-transform: none;
    text-decoration: none;
    #{$alt} & {
      @media #{$medium-up} {
        color: $color-white;
      }
    }
  }
  .sign-out-link {
    margin-left: 7px;
    color: $color-darker-gray;
    font-weight: normal;
    text-transform: none;
    text-decoration: none;
    #{$alt} & {
      color: $color-white;
    }
  }
}

@media only screen and (max-width: 370px) {
  .page-branding__logo,
  .is-alternate .page-branding__logo,
  .brand-aerin .is-alternate .page-branding__logo,
  .brand-renutriv .is-alternate .page-branding__logo,
  .is-sticky .page-branding__logo {
    background-size: 140px 19px;
    width: 140px;
    height: 19px;
  }
}

@media #{$medium-up} {
  .loyalty_popover {
    padding-left: 1em;
    &__input {
      .form-submit {
        width: 120px;
      }
    }
    &__checkbox-container {
      width: 100%;
    }
  }
}

.loyalty_popover {
  &__header {
    @media #{$medium-up} {
      font-size: 5em;
    }
    .valid_unused &,
    .invalid_unused &,
    .valid_used &,
    .invalid_used & {
      @media #{$medium-up} {
        font-size: 4.2em;
      }
    }
  }
  &__promo-header {
    @media #{$medium-up} {
      font-size: 2.1em;
    }
  }
  .loyalty_popover__checkbox-container {
    .loyalty_popover__checkbox-label {
      padding-left: 2em;
    }
  }
  &.valid_unused {
    .button {
      &:last-child {
        @media #{$medium-up} {
          margin-left: 30%;
        }
      }
    }
  }
  &.invalid_unused {
    .form-password {
      display: block;
      float: none;
    }
    .form-submit {
      margin-top: 10px;
    }
    .password-field {
      &__info {
        position: relative;
        top: 0;
      }
    }
    .loyalty_popover__checkbox-label {
      &:after,
      &:before {
        background: $color-navy;
        border: 1px solid $color-medium-gray;
      }
      &:after {
        background-image: url('/media/images/background-images/background-image12.png');
        background-repeat: no-repeat;
        background-size: 13px 13px;
      }
    }
  }
}

div.my-feed-tray--loyalty {
  .loyalty-offer__text {
    div.loyalty-offer__text-inner {
      text-transform: none;
    }
  }
  div.loyalty-offer__link-container {
    position: inherit;
  }
  .my-feed-tray__inner {
    .my-feed-loyalty__joined {
      a.my-feed-loyalty__account {
        position: relative;
      }
    }
  }
}

.password-field__info {
  .password-field__rules {
    li {
      color: $color-navy;
    }
  }
}
