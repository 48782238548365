.signin-overlay-wrapper {
  .sign-in-component {
    &--loyalty {
      &.fb-enabled {
        &.sign-in {
          &.registration {
            @media #{$medium-up} {
              height: 690px !important;
            }
          }
        }
      }
    }
  }
}
