#bt_settings {
  .bt-content {
    .slide-toggle {
      background-image: url('/media/images/cookies/m_all-off_de.png');
      @media #{$medium-up} {
        background-image: url('/media/images/cookies/all-off_de.png');
      }
    }
    .mask {
      .slide-toggle {
        background-image: url('/media/images/cookies/m_all-on_de.png');
        @media #{$medium-up} {
          background-image: url('/media/images/cookies/all-on_de.png');
        }
      }
    }
  }
}
