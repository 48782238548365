.page-footer {
  @media #{$medium-up} {
    .footer-signup-email {
      width: 35%;
    }
  }
  @media #{$medium-only} {
    .footer-signup-email {
      width: 45%;
      padding-right: 1%;
    }
    .email-signup__section {
      width: 100%;
    }
    .footer-signup-email {
      .email-signup {
        .email-signup__section {
          .form-text {
            width: 65%;
          }
          .form-submit {
            width: 31%;
          }
        }
      }
    }
  }
}
