@media #{$large-up} {
  .device-pc.section-book-appointment {
    .page-header {
      min-height: 82px;
    }
  }
}

#appointment-book-container {
  .box-price-time-container {
    margin-top: 5px;
    clear: left;
  }
  .box-price-time {
    position: relative;
    float: left;
    width: 65px;
    height: 30px;
    margin-right: 20px;
    margin-left: -15px;
    p {
      text-align: center;
      margin-top: 10px;
      color: $color-black;
      display: block;
      position: absolute;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &.counters {
    .appt-book-page-header {
      @media #{$small-only} {
        margin-top: 16.5%;
      }
    }
  }
  .appointments-container {
    @media #{$medium-up} {
      .sign-in-container {
        .sign-in-form-container {
          .login__password {
            width: 48%;
          }
        }
      }
    }
    .step3 {
      .book-appt-container {
        .appt-book-first-name,
        .appt-book-last-name,
        .appt-book-email,
        .appt-book-mobile,
        .login__password {
          color: $color-navy;
        }
        .appointment_privacy_legal_text {
          span {
            float: left;
            padding-top: 20px;
            font-size: 12px;
            a {
              text-decoration: underline;
            }
          }
        }
      }
      .mobile-phone-fieldset {
        @media #{$small-up} {
          float: right;
        }
        .appt-book-mobile-prefix {
          width: 80px;
          float: left;
          border-right: 0;
        }
        .appt-book-mobile {
          width: calc(100% - 80px);
        }
      }
    }
  }
  .confirmation {
    .confirmation-container {
      .artist {
        display: none;
      }
    }
  }
  .my-appointments {
    .artist {
      display: none;
    }
    .appointments-buttons {
      .appt-book-link {
        white-space: nowrap;
      }
    }
  }
}

@media #{$large-only} {
  .appt-book-overlay {
    &.appt-book-datepicker-overlay {
      .overlay-content-container {
        padding: 50px 40px;
      }
    }
  }
}

.appt-book {
  .book-now-bar {
    max-width: 280px;
  }
  .service-select {
    .service {
      .service-details {
        h3 {
          &.service-title {
            line-height: 1em;
            margin-top: 0;
            @media #{$medium-only} {
              font-size: 26px;
            }
          }
        }
        .lesson_description {
          p {
            @media #{$medium-only} {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

@media #{$small-only} {
  #appointment-book-sections {
    .service-select {
      .services-renutrive {
        padding-top: 5px;
        .section-header__image {
          padding-left: 48px;
        }
      }
    }
    .appt-book-location-selector {
      margin-top: 10%;
    }
    .book-appt-container {
      input {
        font-size: 9px;
        &::placeholder {
          font-size: 9px;
        }
      }
    }
    .basic-responsive {
      .location-select-section-content {
        &__title {
          font-size: 40px;
        }
        &__subhead {
          min-height: 415px;
        }
        &__desc {
          width: 100%;
          margin-top: 5%;
          text-align: center;
        }
        &__msg {
          width: 100%;
          margin-left: 0%;
        }
      }
    }
  }
}
