.section-store-locator.device-pc {
  .store-locator {
    .local-search-form {
      .local-search-form__city {
        float: left;
        width: 275px !important;
      }
    }
    .local-search__search-container {
      .doors-results__pager {
        overflow: hidden;
        height: auto;
        a.doors-results__pager-view-all {
          width: 35%;
          line-height: 20px;
        }
        ul.doors-results__pager-pages {
          width: 64%;
          padding: 0 0 0 10px;
          margin: 0;
          li {
            line-height: 40px;
          }
        }
      }
    }
  }
}

.cs-quick-info {
  .cs-quick-info__block {
    .lpStaticButton {
      tr.lpPoweredBy {
        display: none;
      }
    }
  }
}

.section-store-locator.device-pc {
  .store-locator {
    .local-search__option {
      filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#ffffff', endColorstr='#eaeaea');
      -ms-filter: "progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#ffffff', endColorstr='#eaeaea')";
    }
    .local-search__option.active {
      filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#ffffff', endColorstr='#ffffff');
      -ms-filter: "progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#ffffff', endColorstr='#ffffff')";
    }
    .local-search__option.local-search__option-directions {
      width: 57%;
      padding: 0 12px;
    }
    .local-search__option.local-search__option-stores {
      width: 43%;
      padding: 0 10px;
    }
  }
}

#search-help {
  .search-help__content {
    .help-number {
      font-weight: normal;
      a {
        display: block;
        font-weight: bold;
      }
    }
  }
}

.contact-form {
  .address-form__fieldset {
    .address-form__registered-container {
      label {
        width: 100%;
        float: left;
      }
      span label {
        width: auto;
        float: left;
      }
    }
  }
}

.page-main .mpp__product-sort-container {
  .mpp__product-sort {
    @include min-width(220px);
  }
}

.page-footer {
  .menu-reference.footer-about-estee {
    .menu {
      a {
        display: inline;
      }
      .menu-container.depth-1 {
        .menu {
          a {
            display: block;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (orientation: portrait) {
  #results-container {
    #col-2 {
      .results-header-container {
        h2 {
          letter-spacing: -0.03em;
        }
        .page {
          padding: 0 4px;
        }
      }
    }
  }
}

#results-container {
  .search-product.result {
    .shade-select-container {
      .swatch__container {
        .swatch--1 {
          border-radius: 11px;
        }
      }
    }
    .shades {
      .swatches--single {
        .swatch__container {
          div {
            border-radius: 8px;
          }
        }
      }
    }
  }
}

body.ie-8 {
  #results-container {
    .search-product.result {
      .shade-select-container {
        .swatch__container {
          margin-top: 5px;
        }
      }
    }
  }
}

.page-sticky-footer .page-sticky-footer__left .node-elc-nodeblock.special-offers-elc-nodeblock {
  border-left: 0;
}

.careers-page {
  .careers-landing {
    .video_youtube {
      margin-bottom: 10px !important;
      .headline--primary {
        font-size: 55px !important;
      }
    }
  }
}

.customer-service {
  .sidebar-page__sidebar {
    .menu {
      a {
        word-break: break-word;
      }
    }
  }
}

.formatter-estee-edit {
  .formatter-estee-edit__content {
    blockquote:before {
      content: '\201D';
    }
    .pull-quote:before {
      content: '\201D';
    }
  }
}

.ff-results {
  .ff-results__recommendations {
    .ff-results__recommended-products {
      .sku-brief-editorial__image {
        width: 100%;
      }
    }
  }
  .ff-results__matches {
    .ff-results__regimen-steps {
      .ff-results__regimen-step {
        .product_brief__image-container {
          width: 100%;
          height: 100%;
        }
        .product_brief__image {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.email_popover {
  .email_popover__disclaimer {
    top: 260px !important;
  }
}

.user-logged-out {
  a {
    &.user-logged-out__link {
      letter-spacing: 0.05em;
    }
  }
}
