.spp-product__anchor-nav li {
  padding: 0 5px;
}

.spp {
  .product-full {
    .product-full__description {
      .product-full__detail-link {
        width: 100%;
        margin-top: 15px;
      }
    }
  }
}

.spp-product__mini-bag-quantity {
  width: 115px;
}

.spp-product__mini-bag-add-button {
  width: 111px;
}

#waitlist-overlay {
  height: auto;
  p {
    line-height: 18px;
    margin: 10px 0;
  }
  #waitlist-form {
    .form-item {
      margin-bottom: 10px;
    }
  }
}

.spp {
  .product-full {
    li.temp-out-of-stock {
      width: 232px !important;
      a.cta {
        margin-left: 0px !important;
        margin-top: 3px;
      }
    }
  }
}

.brand-renutriv {
  .main-content {
    .button--dark {
      text-transform: uppercase !important;
    }
  }
}

.product-vto__youcam-module-container {
  @media #{$medium-up} {
    z-index: 99;
  }
}

.product-vto {
  .cta-vto {
    z-index: 98;
  }
}
