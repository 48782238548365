#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-review {
        .pr-rd-footer {
          .pr-helpful-yes {
            .pr-helpful-count {
              &:before {
                content: 'Ja (';
              }
              &:after {
                content: ')';
              }
            }
          }
          .pr-helpful-no {
            .pr-helpful-count {
              &:before {
                content: 'Nein (';
              }
              &:after {
                content: ')';
              }
            }
          }
          .pr-rd-flag-review-container {
            margin-left: 0;
            @media #{$small-only} {
              @media #{$portrait} {
                display: block;
              }
            }
          }
        }
      }
      .pr-rd-main-header {
        @media #{$small-only} {
          margin-top: 20px;
        }
        .pr-rd-main-header-search {
          width: 33%;
          @media #{$small-only} {
            width: 100%;
          }
        }
        .pr-multiselect[data-reactid-powerreviews$='wiealtsindsie'] {
          &::before {
            content: 'FILTER:';
            font-size: 12px;
            #{$renutriv} & {
              color: $color-white;
              font-family: $roboto-condensed;
            }
          }
        }
        .pr-rd-review-header-contents {
          width: 56.6%;
          @media #{$small-only} {
            width: 100%;
          }
          .pr-multiselect-button {
            span {
              font-size: 12px;
            }
            &-seitwievielenjahrenverwendensieestéelauderprodukte,
            &-wielangeverwendensiebereitsestéelauder {
              display: none;
            }
          }
        }
        .pr-multiselect[data-reactid-powerreviews$='produkte'] {
          display: none;
        }
        .pr-rd-review-header-sorts {
          .pr-rd-sort-group {
            &::before {
              content: 'SORTIEREN: ';
              font-size: 12px;
              @media #{$small-only} {
                font-size: 11px;
              }
            }
          }
        }
        .pr-rd-search-container {
          .pr-rd-search-reviews-icon-button {
            width: 60px;
            .pr-search-icon {
              &::after {
                content: 'SUCHEN';
              }
            }
          }
          .pr-rd-search-reviews-input {
            padding-right: 70px;
            .pr-rd-search-reviews-clear-button {
              right: 70px;
            }
          }
        }
        .pr-rd-sort {
          font-size: 12px;
          @media #{$small-only} {
            font-size: 11px;
            top: 1.5px;
          }
        }
      }
      .pr-rd-main-footer {
        .pr-rd-content-block {
          .pr-rd-to-top {
            padding-left: 14%;
            @media #{$small-only} {
              padding: 0;
            }
          }
        }
      }
    }
    .pr-review-snapshot {
      .pr-review-snapshot-block-container {
        padding-bottom: 30px;
      }
      .pr-review-snapshot-snippets {
        .pr-snippet-stars-reco-stars {
          .pr-snippet {
            .pr-snippet-read-and-write {
              .pr-snippet-write-review-link {
                top: 58px;
                @media #{$small-only} {
                  top: 60px;
                }
              }
            }
          }
          .pr-snippet-rating-decimal {
            &:after {
              content: ' von 5';
            }
          }
        }
      }
    }
  }
}

.write_a_review__container {
  #pr-write {
    .p-w-r {
      .form-group[data-reactid-powerreviews$='ag139334_msq74211'],
      .form-group[data-reactid-powerreviews$='ag140731_msq75279'] {
        display: none;
      }
    }
  }
}
