.mpp__product {
  .button--secondary {
    line-height: normal;
  }
}

.brand-renutriv .product_brief__buttons--non-shaded .product_brief__button--shop-now {
  line-height: 33px;
}

@media (max-width: 425px) {
  .product-brief__button-quickshop {
    line-height: 22px !important;
  }
  .product-brief__button-quickshop--close {
    text-indent: 0;
    background-image: none;
  }
}

@media (max-width: 490px) {
  .continue-buttons .go-shopping {
    line-height: 20px;
  }
}

.device-mobile {
  .account-favorites__list {
    .button--dark-secondary {
      line-height: normal;
    }
  }
}

.offer-code-panel {
  input[type='submit'] {
    width: 100% !important;
    letter-spacing: 0.07em;
    padding: 0px;
  }
}

#cboxWrapper {
  .wishlist-confirm__container {
    .wishlist-confirm__button-close {
      line-height: 2px;
    }
  }
}

#address_book {
  .address-book-info {
    .address_display {
      .controls {
        .billing-address {
          border: none;
        }
      }
    }
  }
}

.mpp-compare {
  .compare_add_to_bag {
    width: 100%;
    left: 0;
    a {
      border: 1px solid #808080;
      color: #ffffff;
    }
  }
}

.spp {
  .spp-product__anchor {
    .spp-product__anchor-tab {
      top: 8%;
    }
  }
}

.hero-block__boutique-link--label {
  width: 170px;
  padding-left: 40px;
  text-align: center;
}

.contact-form {
  .contact-form__section {
    max-width: 569px;
  }
}

#holiday-2017_container {
  .mpp__product-grid h3,
  .mpp__product-grid h4 {
    font-family: 'Optima Display Roman', 'OptimaDisplayLight' !important;
  }
}
