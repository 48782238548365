.checkout {
  .social-login {
    &__divider {
      width: 100%;
    }
  }
}
/* Account signin page styling */
.sign-in-page {
  .divider {
    display: none;
  }
}

.social-login {
  &__email-opt-in {
    .email-optin {
      text-transform: none;
      font-size: 12px;
      margin-top: 13px;
      letter-spacing: 0.05em;
      text-align: $ldirection;
    }
  }
  &__terms {
    text-align: $ldirection;
    padding: 0;
    p {
      text-transform: none;
      font-size: 12px;
    }
  }
  label {
    text-transform: none;
    margin-top: 10px;
    text-align: $ldirection;
  }
}

.device-mobile {
  .sign-in-component {
    .social-login {
      padding: 20px;
      &__divider {
        width: 100%;
      }
      &__terms {
        p {
          line-height: 18px;
        }
      }
    }
  }
  .social-info {
    &__header {
      font-size: 20px;
      font-weight: bold;
    }
  }
}
/* GNAV styling */
#cboxLoadedContent {
  .social-login.gnav {
    @include swap_direction(margin, 0 100px);
    margin: 0;
    .social-login {
      &__divider {
        width: 100%;
      }
    }
    .email-optin {
      text-transform: none;
      letter-spacing: 0.05em;
      margin-top: 20px;
    }
    &__terms {
      text-align: $ldirection;
    }
    .tooltip {
      display: block;
    }
  }
}
/* Order confirmation page */
#confirm {
  .pg_wrapper {
    .social-login {
      padding: 0;
      &__container {
        text-align: $ldirection;
        padding-bottom: 0;
        margin-bottom: 20px;
        width: auto;
      }
      &__terms,
      &__divider {
        display: none;
      }
      &__title {
        display: block;
        font-size: 40px;
        font-family: $optimalight;
        text-transform: capitalize;
        letter-spacing: 0;
      }
      &__info {
        margin-bottom: 10px;
        display: block;
      }
      &__email-opt-in {
        padding-top: 15px;
      }
      &__opt-in-label {
        text-transform: none;
        padding-top: 0;
        font-size: 15px;
        letter-spacing: 0.05em;
      }
      .fb-overlay-social-info {
        display: inline-flex;
      }
    }
  }
}
/* Profile preferences page */
.account-profile {
  &__connected-accounts {
    text-transform: uppercase;
    font-size: 12px;
  }
}

.device-pc {
  .social-login {
    &__container {
      margin: 0 auto;
      width: 88%;
    }
    &__divider {
      width: 100%;
    }
  }
  .social-info {
    padding-top: 0;
    border-top: none;
    padding-#{$ldirection}: 19px;
    @media #{$medium-only} {
      margin-#{$ldirection}: 25%;
    }
    @media #{$large-up} {
      margin-#{$ldirection}: 25%;
    }
    @media #{$xlarge-up} {
      margin-#{$ldirection}: 20%;
    }
    &__header {
      font-size: 30px;
    }
  }
}

.page-wrapper {
  .social-login {
    .tooltip {
      display: block;
    }
  }
}
/* Tooltip */
.social-login,
.social-info {
  .tooltip {
    text-transform: none;
    letter-spacing: 0.05em;
    font-size: 15px;
    text-align: $ldirection;
    position: relative;
    display: inline-block;
    width: 100%;
    .tooltiptext-over {
      visibility: hidden;
      width: 100%;
      background-color: $color-dark-gray;
      color: $color-white;
      text-align: justify;
      border-radius: 6px;
      padding: 10px;
      position: absolute;
      z-index: 99;
      left: 0%;
      opacity: 0;
      transition: opacity 0.3s;
      font-weight: normal;
      bottom: 100%;
    }
    a {
      color: $color-white;
      text-decoration: underline;
    }
    .tooltiptext-over::before {
      content: '';
      position: absolute;
      top: 100%;
      left: 10%;
      border-width: 5px;
      border-style: solid;
      border-color: $color-dark-gray transparent transparent transparent;
    }
    &:hover .tooltiptext-over {
      visibility: visible;
      opacity: 1;
    }
  }
}

.fb-disclaimer-error {
  color: $color-red;
  padding-bottom: 10px;
}

.fb-overlay-container {
  width: 265px;
  height: 40px;
  position: relative;
  margin: 0 auto;
  .fb-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }
}

.fb-overlay-social-info {
  width: 164px;
  height: 22px;
  position: relative;
  .fb-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }
}

label {
  &.fb-disclaimer {
    text-transform: none;
  }
}
