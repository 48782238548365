.welcome15-overlay.alt_layout1 {
  .email_popover {
    .email_popover__social_icons {
      top: 335px;
    }
  }
}

#waitlist-overlay {
  #waitlist-form {
    .form-item label {
      text-transform: none;
    }
  }
}
