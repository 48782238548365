.compare-checkbox-wrapper {
  .compare-checkbox-label {
    &:before,
    &:after {
      left: 55px;
    }
  }
}

.extended-mpp {
  .mpp__product {
    .product_brief__button-panel.button-color-picker {
      line-height: normal !important;
    }
  }
}
